@tailwind base;
@tailwind components;
@tailwind utilities;

$sidebar-bg-color: #fff;
$sidebar-color: #1c1c1c;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #05a772 !default;
$submenu-bg-color: #fff !default;
$submenu-bg-color-collapsed: #f3f3f3 !default;
$icon-bg-color: #ffffff !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar {
  .pro-menu {
    .pro-menu-item {
      &.active {
        box-shadow: -1px -2px 10px -10px rgba(0,0,0,0.75) inset;
        -webkit-box-shadow: -1px -2px 10px -10px rgba(0,0,0,0.75) inset;
        -moz-box-shadow: -1px -2px 10px -10px rgba(0,0,0,0.75) inset;

        color: #05a772;

        .pro-item-content {
          color: #05a772;
          font-weight: 600;
        }
      }
    }
  }
}

h1 {
  @apply text-4xl;
  @apply font-medium;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}

.active-link {
  color: white;
}

.content {
  background: rgba(241,245,249, 0.8);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-scroller {
  max-height: 80vh;

}